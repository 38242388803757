import { SortDirectionEnum, SortTypeConfig } from '@remberg/global/common/core';
import { DialogButtonConfig, SharedDialogTypeEnum, StaticSelectOptionEnum } from './enums';
import { SortField } from './sort';
import { TableColumnData, TableColumnsConfig } from './table-columns';

export type DialogConfigBase<E extends string, D extends DialogResultBase & { dialogId?: E }> = {
  buttonsConfig?: DialogButtonConfig;
  confirmButtonText?: string;
  confirmButtonIcon?: string;
  cancelButtonText?: string;
  headerText?: string;
  htmlContent?: string;
  contentText?: string;
  headerIcon?: string;
  footerText?: string;
  footerIcon?: string;
  confirmationCallback?: (dialogResult: D) => void;
  cancellationCallback?: (dialogResult: D) => void;
};

export interface DialogResultBase {
  isConfirmed?: boolean;
  isCancelled?: boolean;
}

export type SharedDialogData =
  | {
      dialogId: SharedDialogTypeEnum.GenericConfirmation;
      payload?: any;
    }
  | {
      dialogId: SharedDialogTypeEnum.GenericPdfPreview;
      data: GenericPDFPreviewDialogData;
    }
  | {
      dialogId: SharedDialogTypeEnum.ModalSimpleSelectDialog;
      data: SimpleSelectDialog;
    };

export type SharedDialogConfig = DialogConfigBase<SharedDialogTypeEnum, SharedDialogData> &
  SharedDialogData;

export interface GenericPDFPreviewDialogData {
  blob: Blob;
  filename: string;
  dataUrl: string;
  hintText: string;
}

export interface SortingDialogData<E extends string> {
  sortFields: SortField<E>;
  currentSortField?: string;
  currentSortDirection?: SortDirectionEnum;
  targetDomRect: DOMRect;
}

export interface SortingDialogResult<E extends string> {
  isConfirmed: boolean;
  sorting?: SortTypeConfig<E>;
}

export interface TableColumnSelectionDialogData<E extends string> {
  selectedTableColumnsData: TableColumnData<E>[];
  tableColumnsConfig: TableColumnsConfig<E>;
  targetDomRect: DOMRect;
}

export type SimpleSelectDialog = {
  options: {
    identifier: string;
    label: string;
    icon?: string;
  }[];
  selectedOption?: {
    identifier: string;
    label: string;
    icon?: string;
  };
};

export type PredefinedDialogOption = {
  id: StaticSelectOptionEnum;
  label: string;
};

export const NoneSelectionDialogOption: PredefinedDialogOption = {
  id: StaticSelectOptionEnum.NONE,
  label: $localize`:@@minusnoneminus:-none-`,
};

export const NoneFilterDialogOption: PredefinedDialogOption = {
  id: StaticSelectOptionEnum.NONE,
  label: $localize`:@@minusnoneminus:-none-`,
};

export const AnyFilterDialogOption: PredefinedDialogOption = {
  id: StaticSelectOptionEnum.ANY,
  label: $localize`:@@minusanyminus:-any-`,
};

export interface ConfirmDangerDialogData {
  entityName: string;
  instanceName: string;
  description?: string;
}
