export enum FeatureFlagEnum {
  ASSETS = 'assets',
  ASSETS_NOTES = 'assetsNotes',
  ASSETS_STATUS = 'assetsStatus',
  ASSETS_HIERARCHY = 'assetsHierarchy',
  ASSETS_ORGANIZATIONS_AND_CONTACTS = 'assetsOrganizationsAndContacts',
  ASSETS_WORKORDERS_ADDRESS = 'assetsWorkOrdersAddress',

  QRCODES = 'qrcodes',

  CASES = 'cases',
  CASES_CATEGORY = 'casesCategory',
  CASES_ORGANIZATION = 'casesOrganization',
  CASES_REQUIRE_OEM_FEEDBACK = 'casesRequireOEMFeedback',
  CASES_PUBLIC_CREATION = 'casesPublicCreation',
  CASES_PUBLIC_CREATION_OPTIONAL_EMAIL = 'casesPublicCreationOptionalEmail',

  WORKORDERS = 'workOrders',
  WORKORDERS_ORGANIZATIONS_AND_CONTACTS = 'workOrdersOrganizationsAndContacts',
  WORKORDERS_MAINTENANCEPLANS = 'maintenancePlans',
  WORKORDERS_SCHEDULING = 'scheduling',

  APPOINTMENTS_TEMPORARY = 'appointmentsTemporary',
  WORK_ORDERS_TEMPORARY = 'workOrdersTemporary',
  WORK_ORDERS_HIERARCHY = 'workOrdersHierarchy',

  AI_ASSETS_CHAT_WITH_X = 'aiAssetsChatWithX',
  AI_GLOBAL_QA = 'aiGlobalQA',
  AI_CASES = 'aiCases',
  AI_FORMS_SUMMARY = 'aiFormsSummary',
  AI_FILES_CHAT_WITH_FILE = 'aiFilesChatWithFile',
  AI_USE_EXPERIMENTAL_MODELS = 'aiUseExperimentalModels',

  ASSETS_TEMPORARY = 'assetsTemporary',
  ASSET_TYPES_TEMPORARY = 'assetTypesTemporary',
  CASES_SUMMARY_TEMPORARY = 'casesSummaryTemporary',
  AI_ENFORCE_FEEDBACK_TEMPORARY = 'aiEnforceFeedbackTemporary',
  TICKETS_TEMPORARY = 'ticketsTemporary',

  TASKS = 'tasks',
  NOTES = 'notes',
  API = 'api',
  IOT = 'iot',
  IMPORT = 'import',
  EXPORT = 'export',
  FORMS = 'forms',
  FILES = 'files',
  FILES_DOWNLOAD = 'filesDownload',
  MOBILEAPP = 'mobileApp',
  POWERED_BY_REMBERG = 'poweredByRemberg',
  PARTS = 'parts',
  GLOBAL_MAP = 'globalMap',
  DASHBOARDS = 'dashboards',

  PORTAL = 'portal',
}
